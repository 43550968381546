import { colors, createMuiTheme } from "@material-ui/core";
import { responsiveFontSizes, ThemeProvider } from "@material-ui/core/styles";
import "@fontsource/roboto";
// fetch polyfill for safari and IE 11 compatibility
import "isomorphic-unfetch";
import log from "loglevel";
import { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { ErrorBoundaryComponent } from "./app/common/components/errorBoundaryComponent";
import { Loading } from "./app/common/components/loadingComponent";
import reportWebVitals from "./reportWebVitals";

if (process.env.NODE_ENV === "development") {
  // eslint-disable-next-line global-require
  require("./local-config");
}

const App = lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName App */
      "./app"
    ),
);

log.setDefaultLevel(log.levels.TRACE);

let theme = createMuiTheme({
  mixins: {
    toolbar: {
      height: 48,
    },
  },
  palette: {
    primary: colors.orange,
    secondary: colors.blue,
    error: {
      ...createMuiTheme().palette.error,
      light: colors.red[100],
    },
    text: {
      disabled: colors.grey[700],
    },
  },
  transitions: {
    duration: {
      complex: 188,
      enteringScreen: 110,
      leavingScreen: 98,
      short: 125,
      shorter: 100,
      shortest: 75,
      standard: 150,
    },
  },
});

theme = responsiveFontSizes(theme);

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <ErrorBoundaryComponent>
      <Suspense fallback={<Loading />}>
        <App />
      </Suspense>
    </ErrorBoundaryComponent>
  </ThemeProvider>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(log.debug);
