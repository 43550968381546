import { Button, Card, CardContent, Typography } from "@material-ui/core";
import { ErrorOutline, Refresh } from "@material-ui/icons";
import clsx from "clsx";
import { GraphQLError } from "graphql";
import { PropsWithChildren } from "react";

import { useStyles } from "./style/style";

type Props = {
  /**
   * The root component
   * @default Card
   */
  componentType?: "div" | "Card";
  /**
   * If true only the error icon and the retry button (if set) are showm
   */
  hideTitle?: boolean;
  /**
   * The error text
   * @required
   */
  errorMessage: string | readonly GraphQLError[];
  /**
   * If set, a retry button is shown, on click this action is called
   */
  retryAction?: () => void;
  /**
   * A custom action can be passed, which shows a button with a name, onClick action and an optional icon
   */
  customContent?: JSX.Element;
  /**
   * Root paper elevetion, 0 to hide
   */
  elevation?: number;

  formatError?: boolean;
};

export function Error(props: PropsWithChildren<Props>): JSX.Element {
  const classes = useStyles();
  const { errorMessage, hideTitle, componentType, retryAction, elevation, customContent, formatError } = props;
  const CompType = componentType === "div" || elevation === 0 ? "div" : Card;
  let EComp: JSX.Element;
  let compProps = {};
  /* if (typeof errorMessage === "string") {
    EComp = <Typography color="textSecondary">{errorMessage.replace("GraphQL error: ", "")}</Typography>;
  } else {
    EComp = (
      <>
        {errorMessage.map((e) => {
          return (
            <Typography key={`error_${e.name}`} color="textSecondary">
              {e.message.replace("GraphQL error: ", "")}
            </Typography>
          );
        })}
      </>
    );
  } */

  if (typeof errorMessage === "string") {
    if (formatError) {
      // Suddividiamo il messaggio in righe e le visualizziamo separatamente
      EComp = (
        <>
          {errorMessage
            .replace("GraphQL error: ", "")
            .split("\n")
            .map((line) => (
              <Typography color="textSecondary">{line}</Typography>
            ))}
        </>
      );
    } else {
      // Visualizza il messaggio come singolo blocco
      EComp = <Typography color="textSecondary">{errorMessage.replace("GraphQL error: ", "")}</Typography>;
    }
  } else {
    EComp = (
      <>
        {errorMessage.map((e) => (
          <Typography key={`error_${e.name}`} color="textSecondary">
            {e.message.replace("GraphQL error: ", "")}
          </Typography>
        ))}
      </>
    );
  }

  if (elevation !== 0) {
    compProps = {
      elevation,
    };
  }

  return (
    <CompType {...compProps} className={classes.card}>
      <CardContent className={classes.cardContent}>
        {!hideTitle && (
          <Typography className={classes.title} variant="h2" component="h2">
            Errore
          </Typography>
        )}
        <Typography className={clsx(classes.level, classes.criticalLevel)} variant="h1" component="p">
          <ErrorOutline fontSize="inherit" />
        </Typography>
        {EComp}
        {retryAction && (
          <Button className={classes.retryButton} onClick={retryAction} startIcon={<Refresh />}>
            Riprova
          </Button>
        )}
        {customContent}
      </CardContent>
    </CompType>
  );
}

export const ErrorComponent = Error;
